<template>
    <div>
        <!--begin::Card-->
        <div class="card card-custom">
            <!--begin::Header-->
            <div class="card-header border-0 py-3">
                <div class="card-title align-items-start flex-column">
                    <h3 class="card-label font-weight-bolder text-dark">
                        {{ $t("IMPORTER.IMPORTER") }}
                    </h3>
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body pt-0">
                <div v-if="import_running && !import_finished">
                    <div class="text-center">
                        <b-spinner
                                class="my-10 py-0"
                                style="width: 50px; height: 50px"
                                variant="primary"
                                label="Processing"
                        ></b-spinner>
                        <h5 class="font-weight-bold text-dark mb-5">
                            {{ $t("IMPORTER.RUNNING") }}
                        </h5>
                        <div class="mt-2">
                            <span class="text-dark">{{ status_text }}</span>
                        </div>
                    </div>
                </div>

                <div v-if="import_finished && import_success">
                    <div class="text-center">
            <span class="svg-icon svg-icon-success">
              <span class="svg-icon svg-icon-8x">
                <inline-svg src="media/svg/icons/Navigation/Check.svg"/>
              </span>
            </span>
                        <h5 class="font-weight-bold text-dark mb-5">
                            {{ $t("IMPORTER.SUCCESS") }}
                        </h5>
                        <div class="mt-2">
              <span class="text-dark">{{
                $tc(
                  "IMPORTER.SUCCESS_PRODUCT_COUNT",
                  success_info.product_count
                )
              }}</span>
                        </div>
                        <div class="mt-2">
              <span class="text-dark">{{
                $tc("IMPORTER.SUCCESS_IMAGE_COUNT", success_info.image_total, {
                  count: success_info.image_count,
                })
              }}</span>
                        </div>
                        <div
                                v-if="errors.length"
                                class="d-flex align-items-center bg-light-warning rounded p-5 mt-5"
                        >
              <span class="svg-icon mr-5 svg-icon-warning">
                <span class="svg-icon svg-icon-3x">
                  <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg"/>
                </span>
              </span>
                            <div class="d-flex flex-column flex-grow-1 text-left">
                                <div
                                        v-for="(error, index) in errors"
                                        :key="index"
                                        :class="index ? 'mt-2' : ''"
                                >
                                    <span class="text-dark">{{ error }}</span>
                                </div>
                            </div>
                        </div>
                        <button v-on:click="reset()" class="btn btn-primary px-10 mt-10">
                            {{ $t("IMPORTER.NEW_IMPORT") }}
                        </button>
                    </div>
                </div>

                <div v-if="import_finished && !import_success">
                    <div class="text-center">
            <span class="svg-icon svg-icon-danger">
              <span class="svg-icon svg-icon-8x">
                <inline-svg src="media/svg/icons/Navigation/Close.svg"/>
              </span>
            </span>
                        <h5 class="font-weight-bold text-dark mb-5">
                            {{ $t("IMPORTER.FAIL") }}
                        </h5>
                        <div class="mt-2">
              <span class="text-dark">{{
                $tc("IMPORTER.SUCCESS_PRODUCT_COUNT", 0)
              }}</span>
                        </div>
                        <div
                                v-if="errors.length"
                                class="d-flex align-items-center bg-light-danger rounded p-5 mt-5"
                        >
              <span class="svg-icon mr-5 svg-icon-danger">
                <span class="svg-icon svg-icon-3x">
                  <inline-svg src="media/svg/icons/Code/Error-circle.svg"/>
                </span>
              </span>
                            <div class="d-flex flex-column flex-grow-1 text-left">
                                <div
                                        v-for="(error, index) in errors"
                                        :key="index"
                                        :class="index ? 'mt-2' : ''"
                                >
                                    <span class="text-dark">{{ error }}</span>
                                </div>
                            </div>
                        </div>
                        <button v-on:click="reset()" class="btn btn-primary px-10 mt-10">
                            {{ $t("IMPORTER.NEW_IMPORT") }}
                        </button>
                    </div>
                </div>

                <div v-if="!import_running && !import_finished">
                    <div class="text-center">
                        <div
                                class="d-flex align-items-center mb-9 rounded p-5 bg-light-info"
                        >
              <span class="svg-icon mr-5 svg-icon-info">
                <span class="svg-icon svg-icon-3x">
                  <inline-svg src="media/svg/icons/Code/Info-circle.svg"/>
                </span>
              </span>
                            <div class="d-flex flex-column flex-grow-1 mr-2 text-left">
                                <span>{{ $t("IMPORTER.INFO_FORMAT") }}</span>
                                <span class="mt-2">{{
                  $t("IMPORTER.INFO_MINIMAL_REQUIREMENTS")
                }}</span>
                                <span class="mt-2">{{ $t("IMPORTER.INFO_DUPLICATES") }}</span>
                            </div>
                        </div>

                        <b-form-file
                                v-model="import_file"
                                :browse-text="$t('IMPORTER.BROWSE')"
                                :placeholder="$t('IMPORTER.CHOOSE_FILE')"
                                :drop-placeholder="$t('IMPORTER.DROP_FILE')"
                                accept="text/csv, application/json"
                        ></b-form-file>
                        <button
                                v-on:click="startImport()"
                                :disabled="!import_file"
                                class="btn btn-primary px-10 mt-10"
                        >
                            {{ $t("IMPORTER.START_IMPORT") }}
                        </button>
                    </div>
                </div>
            </div>
            <!--end::Body-->
        </div>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import csvtojson from "csvtojson";
    import axios from "axios";

    export default {
        name: "massimport",
        data() {
            return {
                import_running: false,
                import_finished: false,
                import_success: false,
                status_text: null,
                import_file: null,
                import_data: null,
                errors: [],
                success_info: {
                    product_count: 0,
                    image_count: 0,
                    image_total: 0,
                },
                required_headers: [
                    "name",
                    "brand_division",
                    "sku",
                    "category",
                    "sub_category",
                    "further_sub_category",
                    "description_1",
                    "description_2",
                    "description_3",
                    "gender",
                    "fedas_pck",
                    "colors.name",
                    "colors.code",
                    "colors.weight",
                    "colors.year",
                    "colors.origin",
                    "colors.image",
                    "colors.url",
                    "colors.customs_nr",
                    "colors.laundry.washing",
                    "colors.laundry.bleaching",
                    "colors.laundry.drying",
                    "colors.laundry.ironing",
                    "colors.laundry.professional_cleaning",
                    "colors.components",
                    "colors.sizes.name",
                    "colors.sizes.ean",
                    "colors.sizes.upc",
                    "colors.sizes.rrp",
                ],
                file_headers: [],
            };
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: this.$t("IMPORTER.IMPORTER")},
            ]);
        },
        methods: {
            reset() {
                this.import_running = false;
                this.import_finished = false;
                this.import_success = false;
                this.import_file = null;
                this.import_data = null;
                this.errors = [];
                this.success_info = {
                    product_count: 0,
                    image_count: 0,
                    image_total: 0,
                };
            },
            finishImport(success) {
                this.import_running = false;
                this.import_finished = true;
                this.import_success = success;
            },
            startImport() {

                this.import_running = true;
                this.status_text = this.$t("IMPORTER.STATUS_PARSING");
                const reader = new FileReader();
                reader.onload = (event) => {

                    if (this.import_file.type === "application/json") {
                        try {
                            this.import_data = JSON.parse(event.target.result);
                            this.postData();
                        } catch (err) {
                            this.errors.push(this.$t("IMPORTER.FAIL_FILE_JSON"));
                            this.finishImport(false);
                        }
                    } else if (this.import_file.type === "text/csv" || this.import_file.type === "application/vnd.ms-excel") {

                        csvtojson({delimiter: "auto"})
                            .fromString(event.target.result)
                            .on("header", (headers) => {
                                this.file_headers = headers;
                            })
                            .then((csv_json) => {
                                try {
                                    this.import_data = this.formatCSVJSON(csv_json);

                                    this.postData();
                                } catch (error) {

                                    this.errors.push(error.message);
                                    this.finishImport(false);
                                }
                            })
                            .catch(() => {
                                this.errors.push(this.$t("IMPORTER.FAIL_FILE_CSV"));
                                this.finishImport(false);
                            });
                    } else {
                        this.errors.push(this.$t("IMPORTER.FAIL_FILE_FORMAT"));
                        this.finishImport(false);
                    }
                };
                reader.onerror = () => {
                    this.errors.push(this.$t("IMPORTER.FAIL_FILE"));
                    this.finishImport(false);
                };
                reader.readAsText(this.import_file);
            },
            formatCSVJSON(csv_json) {

                if (
                    JSON.stringify(this.file_headers.sort()) !=
                    JSON.stringify(this.required_headers.sort())
                ) {
                    throw new Error(this.$t("IMPORTER.FAIL_FILE_CSV_HEADERS"));
                }

                var data = [];
                csv_json.forEach((entry, index) => {
                    var existing_product = data.find(
                        (product) => product.sku === entry.sku
                    );
                    if (existing_product) {

                        var existing_color = existing_product.colors.find(
                            (color) => color.name === entry.colors.name
                        );
                        if (existing_color) {
                            var existing_size = existing_color.sizes.find(
                                (size) => size.name === entry.colors.sizes.name
                            );
                            if (!existing_size) {
                                existing_color.sizes.push(entry.colors.sizes);
                            }
                        } else {
                            try {
                                entry.colors.components = this.parseComponents(
                                    entry.colors.components
                                );
                            } catch {

                                throw new Error(
                                    this.$t("IMPORTER.FAIL_INVALID_VALUE_CSV") +
                                    " 'colors.components' (" +
                                    this.$t("IMPORTER.FAIL_ROW") +
                                    " " +
                                    (index + 1) +
                                    ")"
                                );
                            }
                            entry.colors.sizes = [entry.colors.sizes];
                            existing_product.colors.push(entry.colors);
                        }
                    } else {
                        try {

                            entry.colors.components = this.parseComponents(
                                entry.colors.components
                            );
                        } catch {
                            throw new Error(
                                this.$t("IMPORTER.FAIL_INVALID_VALUE_CSV") +
                                " 'colors.components' (" +
                                this.$t("IMPORTER.FAIL_ROW") +
                                " " +
                                (index + 1) +
                                ")"
                            );
                        }
                        entry.colors.sizes = [entry.colors.sizes];
                        entry.colors = [entry.colors];
                        data.push(entry);
                    }
                });

                return data;
            },
            parseComponents(component_string) {
                if (!component_string) {
                    return null;
                }
                var components_string = component_string.split("/");
                var components = [];
                components_string.forEach((component_string) => {
                    var component_items = component_string.split(":");
                    var name_string = component_items[0];
                    var materials_strings = component_items[1].split(",");
                    var materials = [];
                    materials_strings.forEach((material_string) => {
                        var material_items = material_string.split("%");
                        materials.push({
                            name: material_items[1],
                            amount: material_items[0],
                        });
                    });
                    components.push({name: name_string, materials: materials});
                });
                return components;
            },
            postData() {


                this.status_text = this.$t("IMPORTER.STATUS_SERVER");
                ApiService.post("import", this.import_data)
                    .then((data) => {

                        this.success_info.product_count = data.data.imported.product_count;
                        if (data.data.images_to_upload.length) {
                            this.uploadImages(data.data.images_to_upload);
                        } else {
                            this.finishImport(true);
                        }
                    })
                    .catch((error) => {
                        switch (error.response.status) {
                            case 422: {
                                error.response.data.errors.forEach((validation_error) => {
                                    this.errors.push(this.parseValidationError(validation_error));
                                });
                                break;
                            }
                            default:
                            case 500: {
                                this.errors.push(
                                    this.parseDatabaseError(error.response.data.message)
                                );
                                break;
                            }
                        }
                        this.finishImport(false);
                    });
            },
            uploadImages(images_to_upload) {
                this.status_text = this.$t("IMPORTER.STATUS_IMAGES");
                this.success_info.image_total = images_to_upload.length;
                const axios_external = axios.create();
                axios_external.defaults.headers.common = {};
                axios_external.defaults.headers.common.accept = "image/jpeg, image/png";
                var upload_promises = [];
                images_to_upload.forEach((image) => {
                    upload_promises.push(
                        axios_external
                            .get(image.image_url, {
                                responseType: "arraybuffer",
                            })
                            .then((response) => {
                                var data_buffer = Buffer.from(response.data, "binary");
                                var magic_number = data_buffer.toString("hex", 0, 4);
                                var mime_type;
                                switch (magic_number) {
                                    case "ffd8ffe0":
                                    case "ffd8ffe1":
                                        mime_type = "image/jpeg";
                                        break;
                                    case "89504e47":
                                        mime_type = "image/png";
                                        break;
                                    default:
                                        throw new Error("Invalid file type");
                                }
                                return {
                                    mime_type: mime_type,
                                    data: data_buffer.toString("base64"),
                                };
                            })
                            .then((request_data) =>
                                ApiService.put("color/" + image.color_id + "/image", request_data)
                            )
                            .then(() => {
                                this.success_info.image_count++;
                            })
                            .catch(() => {
                                this.errors.push(
                                    this.$t("IMPORTER.FAIL_IMAGE") + ": " + image.image_url
                                );
                            })
                    );
                });
                Promise.allSettled(upload_promises).then(() => this.finishImport(true));
            },
            parseValidationError(validation_error) {
                var items = validation_error.param.split(".");
                var parameter = items[items.length - 1];
                var product = parseInt(items[0].replace(/\[|\]/g, "")) + 1;
                var location = this.$t("PRODUCT.PRODUCT") + " " + product;
                if (items.length >= 3) {
                    var color = parseInt(items[1].replace(/\[|\]|colors/g, "")) + 1;
                    location += ", " + this.$t("COLOR.COLOR") + " " + color;
                }
                if (items.length >= 4) {
                    if (items[2].startsWith("sizes")) {
                        var size = parseInt(items[2].replace(/\[|\]|sizes/g, "")) + 1;
                        location += ", " + this.$t("SIZE.SIZE") + " " + size;
                    } else if (items[2].startsWith("components")) {
                        var component =
                            parseInt(items[2].replace(/\[|\]|components/g, "")) + 1;
                        location += ", " + this.$t("COMPONENT.COMPONENT") + " " + component;
                    }
                }
                if (items.length >= 5) {
                    if (items[3].startsWith("materials")) {
                        var material = parseInt(items[3].replace(/\[|\]|materials/g, "")) + 1;
                        location += ", " + this.$t("COMPONENT.MATERIAL") + " " + material;
                    }
                }
                if (validation_error.value) {
                    return (
                        this.$t("IMPORTER.FAIL_INVALID_VALUE") +
                        " '" +
                        parameter +
                        "' (" +
                        location +
                        "): " +
                        validation_error.value
                    );
                } else {
                    return (
                        this.$t("IMPORTER.FAIL_MISSING_VALUE") +
                        " '" +
                        parameter +
                        "' (" +
                        location +
                        ")"
                    );
                }
            },
            parseDatabaseError(error) {
                if (error.startsWith("Duplicate entry")) {
                    var items = error.split("'");
                    return (
                        this.$t("IMPORTER.FAIL_DUPLICATE_VALUE") +
                        " '" +
                        items[3] +
                        "': " +
                        items[1]
                    );
                }
                return error;
            },
        },
    };
</script>
